import React, { useContext, useState, useEffect } from "react";
import { auth, firestore } from "../firebase";

import axios from "axios";
import { useDispatch } from "react-redux";
import { setTheme } from "../store/actions/themeActions";
import { setThemeColor, THEME_COLOR } from "../util/theme";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isAdmin, setIsAdmin] = useState(null);
  const [issalesPerson, setIssalesPerson] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  async function login(email, password) {
    try {
      const res = await axios.post("/api/create-custom-token", { email });
      if (res.status === 200) {
        const { token } = res.data;
        localStorage.setItem("token", token);
        return auth.signInWithEmailAndPassword(email, password);
      } else return false;
    } catch (error) {
      // return auth.signInWithEmailAndPassword(email, password);
      console.log("Error from login: ", error);
      throw new Error(error.message);
    }
  }

  function logout() {
    setIsAdmin(null);
    setIssalesPerson(null);
    localStorage.removeItem("token");
    localStorage.removeItem("trialExpiryModalShown");

    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  // used to disable/enable auth of a user.
  async function toggleUserAuth({ uid, disable = true }) {
    try {
      const res = await axios.post("/api/toggle-user-auth", {
        adminId: currentUser.uid,
        uid,
        disable,
      });
      if (res.status === 200) return true;
      else return false;
    } catch (error) {
      console.log("Error from toggle user auth: ", error.message);
      throw new Error(error.message);
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      if (user) {
        // console.log("curr user: ", user.uid);
        user.getIdTokenResult().then((res) => {
          const claims = res.claims;
          if (claims.type === "admin") {
            // console.log(">>> Admin true <<<<");
            setIsAdmin(true);
            setIssalesPerson(false);
          } else if (
            claims.type === "isSalesPerson" ||
            claims.type === "salesPerson"
          ) {
            // console.log(">>> Sales Person true <<<<");
            setIssalesPerson(true);
            setIsAdmin(false);
          } else {
            // console.log(">>> Nor Admin Neither Sales Person <<<<");
            setIsAdmin(false);
            setIssalesPerson(false);
          }
        });
      } else {
        dispatch(
          setTheme(
            {
              color: THEME_COLOR,
              logo: "",
            },
            true
          )
        );
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    setThemeColor("#7854DF");
    setThemeColor("#9A66EF", "--theme-color-light");
    setThemeColor("#BB9CEE", "--theme-color-light2");
    setThemeColor("#6144D6", "--theme-color-dark");
    if (currentUser) {
      firestore
        .collection("users")
        .where("email", "==", currentUser.email)
        .get()
        .then(async (res) => {
          if (res.docs.length > 0) {
            let useData = res.docs[0].data();
            let theme;
            if (useData.isCorporateAdmin)
              theme = await firestore
                .collection("themes")
                .doc(auth.currentUser.uid)
                .get();
            else if (useData.corporateAdminId)
              theme = await firestore
                .collection("themes")
                .doc(useData.corporateAdminId)
                .get();
            if (theme && theme.exists) {
              dispatch(setTheme(theme.data()));
            }
            setUserDetails(useData);
          }
        });
    }
  }, [currentUser]);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    toggleUserAuth,
    isAdmin,
    issalesPerson,
    userDetails,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
