import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { PersistGate } from "redux-persist/integration/react";
import { firestore, firebase } from "./firebase";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// importing pages
import Index from "./pages/Index";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Details from "./pages/Details";
import ViewContent from "./pages/ViewContent";
import Billing from "./pages/Billing";
import Favourites from "./pages/Favourites";
import Watched from "./pages/Watched";
import SearchResult from "./pages/SearchResult";

import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import PrivateRouteRestricted from "./PrivateRouteRestricted";
import AdminRoute from "./AdminRoute";
import SalesPersonRoute from "./SalesPersonRoute";

import { Provider } from "react-redux";
import { store, persistor } from "./store/index";

import Admin from "./admin/Admin"; // wrapper for Admin // TODO: (maybe) create a separate wrapper for sales person
import SingleAdventure from "./admin/components/SingleAdventure";
import SliderRow from "./admin/components/SliderRow";
import CompanyDetails from "./admin/containers/CompanyDetails";
import AdminIndex from "./admin/containers/AdminIndex";
import AdminHome from "./admin/containers/AdminHome";
import CompanyDetailsPost from "./admin/components/CompanyDetailsPost";
import CompanyForm from "./admin/components/CompanyForm";
import AddSinglePost from "./admin/components/SliderRowPost/AddSinglePost";
import ViewSinglePost from "./admin/components/ViewSinglePost";

import Users from "./admin/components/Users";
import Subscribers from "./admin/components/Subscribers";
import SubscribersSub from "./admin/components/SubscribersSub";
import AccessControl from "./admin/components/AccessControl";
import VirtualGuru from "./pages/VirtualGuru";
import SalesPersonRegistration from "./pages/Registration";
import SalesDashboard from "./pages/salesDashboard";
import SalesPersonLogin from "./pages/Login";
import PublicRoute from "./PublicRoute";
import IndividualIndex from "./admin/containers/IndividualIndex";
import CompanyDashboard from "./pages/CompanyDashboard";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// axios.defaults.baseURL = "https://us-central1-leviosa-dev-7e18c.cloudfunctions.net/api";
// axios.defaults.baseURL = "https://us-central1-leviosa-f3f1e.cloudfunctions.net";
// axios.defaults.baseURL = "http://localhost:5000/leviosa-dev-7e18c/us-central1"; //! dev env local
// axios.defaults.baseURL = "http://localhost:5000/leviosa-f3f1e/us-central1";
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Elements stripe={stripePromise}>
            <BrowserRouter>
              <AuthProvider>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/welcome" />}
                  />

                  <Route exact path="/welcome/:id?" component={Index} />
                  {/* turn these into sales person routes */}

                  <PublicRoute
                    exact
                    path="/salesportal/register"
                    component={SalesPersonRegistration}
                  />
                  <PublicRoute
                    exact
                    path="/salesportal"
                    component={SalesPersonLogin}
                  />
                  <PublicRoute
                    exact
                    path="/salesportal/login"
                    component={SalesPersonLogin}
                  />

                  <SalesPersonRoute
                    exact
                    path="/salesportal/dashboard/:id"
                    // component={() => (
                    //   <Admin>
                    //     <Subscribers />
                    //   </Admin>
                    // )}
                    component={SalesDashboard}
                  />

                  <PrivateRoute
                    exact
                    path="/company-dashboard"
                    component={CompanyDashboard}
                  />

                  <PrivateRoute exact path="/home" component={Home} />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/billing" component={Billing} />
                  <PrivateRouteRestricted
                    exact
                    path="/:details/"
                    component={Details}
                  />
                  <PrivateRouteRestricted
                    exact
                    path="/favourites/all"
                    component={Favourites}
                  />
                  <PrivateRouteRestricted
                    exact
                    path="/virtual-gurus/:id"
                    component={VirtualGuru}
                  />
                  <PrivateRouteRestricted
                    exact
                    path="/watched/all"
                    component={Watched}
                  />
                  <PrivateRouteRestricted
                    exact
                    path="/adventure/:adventure/:row/:id/"
                    component={ViewContent}
                  />
                  <PrivateRouteRestricted
                    exact
                    path="/search/results"
                    component={SearchResult}
                  />

                  <Route exact path="/a/dashboard" component={Dashboard} />
                  <AdminRoute exact path="/a/categories" component={Admin} />
                  <AdminRoute
                    exact
                    path="/a/categories/:categoryId"
                    component={() => (
                      <Admin>
                        <SingleAdventure />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/content-creators"
                    component={Admin}
                  />
                  <AdminRoute exact path="/a/check-in" component={Admin} />
                  <AdminRoute exact path="/a/sales-people" component={Admin} />
                  <AdminRoute exact path="/a/gurus" component={Admin} />
                  <AdminRoute
                    exact
                    path="/a/guru-recommendations/:id"
                    component={Admin}
                  />

                  <AdminRoute
                    exact
                    path="/a/categories/:categoryId/r/:id"
                    component={() => (
                      <Admin>
                        <SliderRow />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/categories/:categoryId/r/:id/:post"
                    component={() => (
                      <Admin>
                        <ViewSinglePost />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/categories/:categoryId/r/:id/p/new"
                    component={() => (
                      <Admin>
                        <AddSinglePost />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/categories/:categoryId/r/:id/p/:post/edit"
                    component={() => (
                      <Admin>
                        <AddSinglePost isEdit="true" />
                      </Admin>
                    )}
                  />

                  <SalesPersonRoute
                    exact
                    path="/a/users"
                    component={() => (
                      <Admin>
                        <Users />
                      </Admin>
                    )}
                  />
                  <SalesPersonRoute
                    exact
                    path="/a/subscribers"
                    component={() => (
                      <Admin>
                        <Subscribers />
                      </Admin>
                    )}
                  />
                  <SalesPersonRoute
                    exact
                    path="/a/subscriberslist"
                    component={() => (
                      <Admin>
                        <SubscribersSub />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/access_control"
                    component={() => (
                      <Admin>
                        <AccessControl />
                      </Admin>
                    )}
                  />

                  <SalesPersonRoute
                    exact
                    path="/a/pricings"
                    component={Admin}
                  />
                  <SalesPersonRoute
                    exact
                    path="/a/company_details"
                    component={() => (
                      <Admin>
                        <CompanyDetails />
                      </Admin>
                    )}
                  />
                  <SalesPersonRoute
                    exact
                    path="/a/company_details/add"
                    component={() => (
                      <Admin>
                        <CompanyForm />
                      </Admin>
                    )}
                  />
                  <SalesPersonRoute
                    exact
                    path="/a/company_details/:id/edit"
                    component={() => (
                      <Admin>
                        <CompanyForm />
                      </Admin>
                    )}
                  />
                  <SalesPersonRoute
                    exact
                    path="/a/company_details/:id/"
                    component={() => (
                      <Admin>
                        <CompanyDetailsPost />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/p/index"
                    component={() => (
                      <Admin>
                        <AdminIndex />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/p/individual-index"
                    component={() => (
                      <Admin>
                        <IndividualIndex />
                      </Admin>
                    )}
                  />
                  <AdminRoute
                    exact
                    path="/a/p/home"
                    component={() => (
                      <Admin>
                        <AdminHome />
                      </Admin>
                    )}
                  />
                </Switch>
              </AuthProvider>
            </BrowserRouter>
          </Elements>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
